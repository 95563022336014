.puzzle {
    position: fixed;
    top: 0px;
    margin: 30px;
    width: 90%;
    height: 90%;
    background-color: #ffffff;
    z-index: 12;
    background-image: url(../img/puzzles/background.svg);
    background-size: cover;
}

.puzzle.achieved {
    background-color: rgb(0, 79, 0);
}

.puzzle.failed {
    background-color: rgb(110, 0, 0);
}

.puzzle-item-square{
    width: 100px;
    height: 100px;
    background-color: white;
}

/* Puzzle grid */

.gridPuzzle {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(3, 130px);
    grid-template-rows: repeat(3, 130px);
}





/* Puzzle colored squares */

.coloredSquarePuzzle {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(3, 130px);
    grid-template-rows: repeat(3, 130px);
}

.square_red{
    background-color: rgb(126, 21, 21);
}

.square_red:hover{
    background-color: rgb(167, 18, 18);
}

.square_blue{
    background-color: rgb(30, 30, 143);
}

.square_blue:hover{
    background-color: rgb(23, 23, 193);
}

/* Puzzle Leaves */

.leaf {
    position: fixed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.beetle {
    position: fixed;
}

.trash {
    position: fixed;
}


/* Puzzle Curve */

#distBar {
    position: fixed;
    left: 500px;
    top: 200px;
    width: 30px;
    height: 100px;
    border: solid 2px gray;
}

#distBarJauge {
    background-color: rgb(214, 26, 51);
}


/* Puzzle Sort */

div.bucket {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

