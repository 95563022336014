.puzzle {
  width: 90%;
  height: 90%;
  z-index: 12;
  background-color: #fff;
  background-image: url("background.8f490f86.svg");
  background-size: cover;
  margin: 30px;
  position: fixed;
  top: 0;
}

.puzzle.achieved {
  background-color: #004f00;
}

.puzzle.failed {
  background-color: #6e0000;
}

.puzzle-item-square {
  width: 100px;
  height: 100px;
  background-color: #fff;
}

.gridPuzzle, .coloredSquarePuzzle {
  grid-template-rows: repeat(3, 130px);
  grid-template-columns: repeat(3, 130px);
  padding: 30px;
  display: grid;
}

.square_red {
  background-color: #7e1515;
}

.square_red:hover {
  background-color: #a71212;
}

.square_blue {
  background-color: #1e1e8f;
}

.square_blue:hover {
  background-color: #1717c1;
}

.leaf {
  -webkit-user-select: none;
  user-select: none;
  position: fixed;
}

.beetle, .trash {
  position: fixed;
}

#distBar {
  width: 30px;
  height: 100px;
  border: 2px solid gray;
  position: fixed;
  top: 200px;
  left: 500px;
}

#distBarJauge {
  background-color: #d61a33;
}

div.bucket {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.f834f8c5.css.map */
